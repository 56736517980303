@use '../../styles/partials/variables';

.piano {
  min-width: 356px;
  background: variables.$piano-body-color;
  border-radius: 10px;
  padding: 0.625rem;
  margin: 32px auto;
  display: inline-flex;
  flex-direction: column;
  overflow: hidden;
  width: 96%;
  max-width: 1080px;
  display: flex;
  box-shadow: 0px 3px 12px rgba(0, 0, 0, 1);

  &__song-select {
    border-radius: 3px;
    margin: 0 3px;
    padding: 0.625rem;
    background: white;
    border: 1px solid white;
    &:hover {
      background: rgb(212, 243, 255);
    }
    &:focus {
      background: white;
      border: 1px solid skyblue;
      outline: none;
    }
    &::selection {
      background: transparent;
    }
  }
  /*
   * Keys
   */
  &__keys {
    display: inline-flex;
    justify-content: center;
    height: 25vw;
    min-height: 100px;
    max-height: 200px;
  }
  &__Key {
    flex-shrink: 0;
  }
}
