@use '../../styles/partials/variables';

.controls {
  background: lighten(variables.$color-black, 10%);
  border-radius: 10px;
  color: variables.$color-white;
  border: 1px solid darken(variables.$color-white, 50%);
  padding: 0.375rem 2rem;
  margin: 0 0 10px;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  align-items: flex-start;
  &__nav {
    display: flex;
    flex-wrap: wrap;
  }
  &__toggle-btn {
    border-radius: 3px;
    margin: 3px;
    padding: 0.625rem;
    border: 0;
    background: variables.$color-white;
    border: 1px solid variables.$color-white;
    &:hover {
      background: rgb(212, 243, 255);
    }
    &:focus {
      background: skyblue;
      border-color: variables.$color-white;
    }
    &:active,
    &--active {
      background: skyblue;
      border-color: skyblue;
    }
    &::selection {
      background: transparent;
    }
  }

  &__select {
    padding: 0.625rem;
  }
}

.activeSong {
  display: flex;
  flex-direction: row;
  width: 100%;
  align-items: center;
  color: #fff;
  &__header {
    background: #292929;
    padding: 0.625rem;
  }
  &__title {
    margin: 0 0 0.25rem 0;
    font-size: 1.25rem;
    font-weight: normal;
  }
  &__artist {
    margin: 0;
    font-size: 0.875rem;
  }
  &__img {
    line-height: 0;
    width: 100%;
    height: 100%;
    min-width: 150px;
    min-height: 150px;
    max-width: 150px;
    max-height: 150px;
    margin: 0 10px 10px;
    border-radius: 10px;
    object-fit: cover;
    object-position: center;
    box-shadow: 0 0 2px rgba(255, 255, 255, 1), 0 0 8px rgba(255, 255, 255, 0.6);
  }
  &__bpm {
    width: 100%;
    margin: 0.625rem;
    font-size: 0.875rem;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

.bpmList {
  overflow-y: scroll;
  list-style: none;
  margin: 0;
  padding: 0.625rem 1.25rem;
  max-height: 132px;
  &__item {
    border-bottom: 1px solid black;
  }
}

.range-controls {
  padding: 0.625rem;
  display: flex;
  flex-direction: column;
  &__label {
    display: flex;
    justify-content: space-between;
  }
}
