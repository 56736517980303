$color-white: rgba(255, 255, 255);
$color-black: rgba(0, 0, 0);
$color-vibrant-blue: rgb(73, 237, 255);
$color-vibrant-green: rgb(15, 228, 129);

$piano-body-color: rgb(16, 16, 16);
$piano-key-color: rgb(255, 255, 255);
$piano-key-sharp-color: $color-black;
$paino-key-active-color: $color-vibrant-blue;
$paino-key-active-lh-color: $color-vibrant-green;
$paino-key-active-rh-color: $color-vibrant-blue;
$piano-key-width: 2.3%;
